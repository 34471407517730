<template>
  <div class="navbar-container d-flex content align-items-center">

    <b-link >
      <b-img
          :src="appLogoImage"
          alt="logo"
          style="height: 50px"
      />
    </b-link>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.user.name }}
            </p>
            <span class="user-status">Klient</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/chemmaster-user-avatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Wyloguj</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BImg,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import CartDropdown from '@/layouts/components/CartDropdown'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown'
import NotificationDropdown from '@/layouts/components/NotificationDropdown.vue'
import store from '@/store'
import Vue from "vue";
import {$themeConfig} from "@themeConfig";

const { appLogoImage } = $themeConfig.app

export default {
  components: {
    BImg,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: {},
      appLogoImage,
      isWebview: Vue.prototype.$workbox !== null,
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    logout() {
      useJwt.logout()
      this.$router.push({ name: 'login' })
    },
  },
}
</script>